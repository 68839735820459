<template>
  <div>
    <v-container>
      <v-row>
        <v-col
          cols="12"
          class="d-flex justify-center"
        >
          <v-card
            max-width="900"
            width="900"
            class="pa-5"
            flat
          >
            <h1 class="title mb-3">
              Dashboard
            </h1>

            <template v-if="initialLoadAttempted.length < 2">
              <v-progress-circular
                indeterminate
                :size="64"
                class="d-block mx-auto"
              />
            </template>

            <template
              v-else-if="
                savedSearchesStatus === 'loaded'
                  && savedSearches.count === 0
                  && downloadsStatus === 'loaded'
                  && downloads.count === 0
              "
            >
              <p>
                Welcome! The dashboard is the place to find your saved searches
                as well as your downloaded files.
              </p>

              <v-btn
                :to="{ name: 'search' }"
                color="primary"
              >
                Get Started
              </v-btn>
            </template>

            <template v-else>
              <h2 class="text-h6 mb-3">
                Saved Searches
              </h2>

              <template v-if="savedSearchesStatus === 'loaded'">
                <template v-if="savedSearches.count">
                  <v-simple-table
                    v-if="viewportWidthAtLeast768"
                    class="wide-saved-search-table"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Options
                          </th>
                          <th class="text-left">
                            Search Name
                          </th>
                          <th class="text-left">
                            Search Type
                          </th>
                          <th class="text-left">
                            Date / Time
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="savedSearch in savedSearches.results"
                          :key="`savedSearch${savedSearch.id}`"
                        >
                          <td>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  :to="
                                    { name: 'search', params: { savedSearchId: savedSearch.id } }
                                  "
                                  aria-label="Run Search"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon>mdi-play</v-icon>
                                </v-btn>
                              </template>
                              <span>Run Search</span>
                            </v-tooltip>

                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  aria-label="View Search Criteria"
                                  :disabled="savedSearchesDeletion !== null"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="
                                    searchCriteria = savedSearch;
                                    searchCriteriaDialogShowing = true;
                                  "
                                >
                                  <v-icon>mdi-magnify</v-icon>
                                </v-btn>
                              </template>
                              <span>View Search Criteria</span>
                            </v-tooltip>

                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  aria-label="Delete File"
                                  :disabled="savedSearchesDeletion !== null"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="deleteSavedSearch(savedSearch.id)"
                                >
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </template>
                              <span>Delete File</span>
                            </v-tooltip>
                          </td>
                          <td>{{ savedSearch.search_name }}</td>
                          <td>{{ capitalizeFirstLetter(savedSearch.search_type) }}</td>
                          <td>{{ new Date(savedSearch.created_on).toLocaleString('en-US') }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <div
                    v-else
                    class="narrow-saved-search-tables"
                  >
                    <table
                      v-for="savedSearch in savedSearches.results"
                      :key="`savedSearch${savedSearch.id}`"
                      class="narrow-saved-search-table"
                    >
                      <tr>
                        <th>Options</th>
                        <td>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                :to="{ name: 'search', params: { savedSearchId: savedSearch.id } }"
                                aria-label="Run Search"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon>mdi-play</v-icon>
                              </v-btn>
                            </template>
                            <span>Run Search</span>
                          </v-tooltip>

                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                aria-label="View Search Criteria"
                                :disabled="savedSearchesDeletion !== null"
                                v-bind="attrs"
                                v-on="on"
                                @click="
                                  searchCriteria = savedSearch;
                                  searchCriteriaDialogShowing = true;
                                "
                              >
                                <v-icon>mdi-magnify</v-icon>
                              </v-btn>
                            </template>
                            <span>View Search Criteria</span>
                          </v-tooltip>

                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                aria-label="Delete File"
                                :disabled="savedSearchesDeletion !== null"
                                v-bind="attrs"
                                v-on="on"
                                @click="deleteSavedSearch(savedSearch.id)"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </template>
                            <span>Delete File</span>
                          </v-tooltip>
                        </td>
                      </tr>
                      <tr>
                        <th>Search Name</th>
                        <td>{{ savedSearch.search_name }}</td>
                      </tr>
                      <tr>
                        <th>Search Type</th>
                        <td>{{ capitalizeFirstLetter(savedSearch.search_type) }}</td>
                      </tr>
                      <tr>
                        <th>Date / Time</th>
                        <td>{{ new Date(savedSearch.created_on).toLocaleString('en-US') }}</td>
                      </tr>
                    </table>
                  </div>

                  <div
                    v-if="savedSearches.previous || savedSearches.next"
                    class="pagination"
                  >
                    <v-btn
                      aria-label="Previous Page"
                      :disabled="savedSearches.previous === null || savedSearchesDeletion !== null"
                      @click="loadSavedSearches(savedSearches.previous)"
                    >
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>

                    <v-btn
                      aria-label="Next Page"
                      :disabled="savedSearches.next === null || savedSearchesDeletion !== null"
                      @click="loadSavedSearches(savedSearches.next)"
                    >
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </div>
                </template>

                <v-alert
                  v-else
                  dense
                  border="left"
                  type="info"
                >
                  You don't have any saved searches yet. To get started, go to
                  <router-link :to="{ name: 'search' }">
                    <!-- eslint-disable-next-line vue/multiline-html-element-content-newline -->
                    the "Search" page</router-link>.
                </v-alert>
              </template>

              <v-progress-circular
                v-else-if="savedSearchesStatus === 'loading'"
                indeterminate
                :size="64"
                class="d-block mx-auto"
              />

              <template v-else-if="savedSearchesStatus === 'error'">
                <v-alert
                  dense
                  border="left"
                  type="error"
                >
                  Unable to load your saved searches due to a communication
                  error. Please check your connection and try again.
                </v-alert>

                <div class="text-right">
                  <v-btn @click="loadSavedSearches">
                    Try Again
                  </v-btn>
                </div>
              </template>

              <h2 class="text-h6 mt-10 mb-3">
                Downloaded Files
              </h2>

              <template v-if="downloadsStatus === 'loaded'">
                <template v-if="downloads.count">
                  <v-simple-table
                    v-if="viewportWidthAtLeast768"
                    class="wide-download-table"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">
                            Options
                          </th>
                          <th class="text-left">
                            Description
                          </th>
                          <th class="text-left">
                            Search Type
                          </th>
                          <th class="text-left">
                            Record Count
                          </th>
                          <th class="text-left">
                            Date / Time
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="download in downloads.results"
                          :key="`download${download.id}`"
                        >
                          <td>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  :href="download.file_url"
                                  aria-label="Download File"
                                  download
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  <v-icon>mdi-download</v-icon>
                                </v-btn>
                              </template>
                              <span>Download File</span>
                            </v-tooltip>

                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  aria-label="View Search Criteria"
                                  :disabled="downloadsDeletion !== null"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="
                                    searchCriteria = download;
                                    searchCriteriaDialogShowing = true;
                                  "
                                >
                                  <v-icon>mdi-magnify</v-icon>
                                </v-btn>
                              </template>
                              <span>View Search Criteria</span>
                            </v-tooltip>

                            <v-tooltip bottom>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                  icon
                                  aria-label="Delete File"
                                  :disabled="downloadsDeletion !== null"
                                  v-bind="attrs"
                                  v-on="on"
                                  @click="deleteDownload(download.id)"
                                >
                                  <v-icon>mdi-delete</v-icon>
                                </v-btn>
                              </template>
                              <span>Delete File</span>
                            </v-tooltip>
                          </td>
                          <td>{{ download.description }}</td>
                          <td>{{ capitalizeFirstLetter(download.search_type) }}</td>
                          <td>{{ formatNumber(download.record_count) }}</td>
                          <td>{{ new Date(download.created_on).toLocaleString('en-US') }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <div
                    v-else
                    class="narrow-download-tables"
                  >
                    <table
                      v-for="download in downloads.results"
                      :key="`download${download.id}`"
                      class="narrow-download-table"
                    >
                      <tr>
                        <th>Options</th>
                        <td>
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                :href="download.file_url"
                                aria-label="Download File"
                                download
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon>mdi-download</v-icon>
                              </v-btn>
                            </template>
                            <span>Download File</span>
                          </v-tooltip>

                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                aria-label="View Search Criteria"
                                :disabled="downloadsDeletion !== null"
                                v-bind="attrs"
                                v-on="on"
                                @click="
                                  searchCriteria = download;
                                  searchCriteriaDialogShowing = true;
                                "
                              >
                                <v-icon>mdi-magnify</v-icon>
                              </v-btn>
                            </template>
                            <span>View Search Criteria</span>
                          </v-tooltip>

                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                aria-label="Delete File"
                                :disabled="downloadsDeletion !== null"
                                v-bind="attrs"
                                v-on="on"
                                @click="deleteDownload(download.id)"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </template>
                            <span>Delete File</span>
                          </v-tooltip>
                        </td>
                      </tr>
                      <tr>
                        <th>Description</th>
                        <td>{{ download.description }}</td>
                      </tr>
                      <tr>
                        <th>Search Type</th>
                        <td>{{ capitalizeFirstLetter(download.search_type) }}</td>
                      </tr>
                      <tr>
                        <th>Record Count</th>
                        <td>{{ formatNumber(download.record_count) }}</td>
                      </tr>
                      <tr>
                        <th>Date / Time</th>
                        <td>{{ new Date(download.created_on).toLocaleString('en-US') }}</td>
                      </tr>
                    </table>
                  </div>

                  <div
                    v-if="downloads.previous || downloads.next"
                    class="pagination"
                  >
                    <v-btn
                      aria-label="Previous Page"
                      :disabled="downloads.previous === null || downloadsDeletion !== null"
                      @click="loadDownloads(downloads.previous)"
                    >
                      <v-icon>mdi-chevron-left</v-icon>
                    </v-btn>

                    <v-btn
                      aria-label="Next Page"
                      :disabled="downloads.next === null || downloadsDeletion !== null"
                      @click="loadDownloads(downloads.next)"
                    >
                      <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                  </div>
                </template>

                <v-alert
                  v-else
                  dense
                  border="left"
                  type="info"
                >
                  You don't have any downloads yet. To get started,
                  <router-link :to="{ name: 'search' }">
                    <!-- eslint-disable-next-line vue/multiline-html-element-content-newline -->
                    submit a search</router-link>.
                </v-alert>
              </template>

              <v-progress-circular
                v-else-if="downloadsStatus === 'loading'"
                indeterminate
                :size="64"
                class="d-block mx-auto"
              />

              <template v-else-if="downloadsStatus === 'error'">
                <v-alert
                  dense
                  border="left"
                  type="error"
                >
                  Unable to load your downloads due to a communication error.
                  Please check your connection and try again.
                </v-alert>

                <div class="text-right">
                  <v-btn @click="loadDownloads">
                    Try Again
                  </v-btn>
                </div>
              </template>
            </template>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog
      v-model="searchCriteriaDialogShowing"
      scrollable
      max-width="600"
    >
      <v-card>
        <v-card-title class="text-h5">
          Search Criteria
        </v-card-title>

        <v-divider />

        <v-card-text style="max-height: 300px;">
          <table
            v-if="searchCriteria"
            class="search-criteria-table"
          >
            <tr>
              <th>Search Type</th>
              <td>{{ capitalizeFirstLetter(searchCriteria.search_type) }}</td>
            </tr>
            <tr>
              <th>State</th>
              <td>{{ searchCriteria.state }}</td>
            </tr>
            <tr v-if="searchCriteria.parameters.zips.length">
              <th>Zip Codes</th>
              <td>{{ toListSentence(searchCriteria.parameters.zips) }}</td>
            </tr>
            <tr v-if="searchCriteria.parameters.towns.length">
              <th>Towns / Cities</th>
              <td>{{ toListSentence(searchCriteria.parameters.towns) }}</td>
            </tr>
            <tr v-if="searchCriteria.parameters.counties.length">
              <th>Counties</th>
              <td>{{ toListSentence(searchCriteria.parameters.counties) }}</td>
            </tr>

            <template v-if="searchCriteria.search_type === 'consumer'">
              <tr v-if="searchCriteria.parameters.filters.gender.length">
                <th>Gender</th>
                <td>
                  {{
                    toListSentence(
                      getFilterChoicesFromMap(
                        searchCriteria.parameters.filters.gender,
                        'consumer',
                        'gender',
                      )
                    )
                  }}
                </td>
              </tr>
              <tr v-if="searchCriteria.parameters.filters.marital_status.length">
                <th>Marital Status</th>
                <td>
                  {{
                    toListSentence(
                      getFilterChoicesFromMap(
                        searchCriteria.parameters.filters.marital_status,
                        'consumer',
                        'marital_status',
                      )
                    )
                  }}
                </td>
              </tr>
              <tr v-if="searchCriteria.parameters.filters.homeowner_status.length">
                <th>Homeowner Status</th>
                <td>
                  {{
                    toListSentence(
                      getFilterChoicesFromMap(
                        searchCriteria.parameters.filters.homeowner_status,
                        'consumer',
                        'homeowner_status',
                      )
                    )
                  }}
                </td>
              </tr>
              <tr v-if="searchCriteria.parameters.filters.age_range.length">
                <th>Age Range</th>
                <td>
                  {{
                    toListSentence(
                      getFilterChoicesFromMap(
                        searchCriteria.parameters.filters.age_range,
                        'consumer',
                        'age_range',
                      )
                    )
                  }}
                </td>
              </tr>
              <tr
                v-if="searchCriteria.parameters.filters.year_of_birth.to !== null"
              >
                <th>Year of Birth</th>
                <td>
                  {{ searchCriteria.parameters.filters.year_of_birth.from }}

                  <template
                    v-if="
                      searchCriteria.parameters.filters.year_of_birth.from
                        !== searchCriteria.parameters.filters.year_of_birth.to
                    "
                  >
                    to

                    {{ searchCriteria.parameters.filters.year_of_birth.to }}
                  </template>
                </td>
              </tr>
              <tr v-if="searchCriteria.parameters.filters.month_of_birth.length">
                <th>Month of Birth</th>
                <td>
                  {{
                    toListSentence(
                      getFilterChoicesFromMap(
                        searchCriteria.parameters.filters.month_of_birth,
                        'consumer',
                        'month_of_birth',
                      )
                    )
                  }}
                </td>
              </tr>
              <tr v-if="searchCriteria.parameters.filters.day_of_birth.length">
                <th>Day of Birth</th>
                <td>{{ toListSentence(searchCriteria.parameters.filters.day_of_birth) }}</td>
              </tr>
              <tr v-if="searchCriteria.parameters.filters.net_worth.length">
                <th>Net Worth</th>
                <td>
                  {{
                    toListSentence(
                      getFilterChoicesFromMap(
                        searchCriteria.parameters.filters.net_worth,
                        'consumer',
                        'net_worth',
                      )
                    )
                  }}
                </td>
              </tr>
              <tr v-if="searchCriteria.parameters.filters.income.length">
                <th>Income</th>
                <td>
                  {{
                    toListSentence(
                      getFilterChoicesFromMap(
                        searchCriteria.parameters.filters.income,
                        'consumer',
                        'income',
                      )
                    )
                  }}
                </td>
              </tr>
              <tr v-if="searchCriteria.parameters.filters.credit_rating.length">
                <th>Credit Rating</th>
                <td>
                  {{
                    toListSentence(
                      getFilterChoicesFromMap(
                        searchCriteria.parameters.filters.credit_rating,
                        'consumer',
                        'credit_rating',
                      )
                    )
                  }}
                </td>
              </tr>
              <tr v-if="searchCriteria.parameters.filters.dwell_type.length">
                <th>Dwell Type</th>
                <td>
                  {{
                    toListSentence(
                      getFilterChoicesFromMap(
                        searchCriteria.parameters.filters.dwell_type,
                        'consumer',
                        'dwell_type',
                      )
                    )
                  }}
                </td>
              </tr>
              <tr
                v-if="
                  searchCriteria.parameters.filters.phone_number !== undefined
                    && searchCriteria.parameters.filters.phone_number.length
                "
              >
                <th>Have Phone Number</th>
                <td>
                  {{
                    toListSentence(
                      getFilterChoicesFromMap(
                        searchCriteria.parameters.filters.phone_number,
                        'consumer',
                        'phone_number',
                      )
                    )
                  }}
                </td>
              </tr>
              <tr v-if="searchCriteria.parameters.filters.email_address.length">
                <th>Have Email Address</th>
                <td>
                  {{
                    toListSentence(
                      getFilterChoicesFromMap(
                        searchCriteria.parameters.filters.email_address,
                        'consumer',
                        'email_address',
                      )
                    )
                  }}
                </td>
              </tr>
              <tr v-if="searchCriteria.parameters.filters.ethnic_group.length">
                <th>Ethnic Group</th>
                <td>
                  {{
                    toListSentence(
                      getFilterChoicesFromMap(
                        searchCriteria.parameters.filters.ethnic_group,
                        'consumer',
                        'ethnic_group',
                      )
                    )
                  }}
                </td>
              </tr>
              <tr v-if="searchCriteria.parameters.filters.religion.length">
                <th>Religion</th>
                <td>
                  {{
                    toListSentence(
                      getFilterChoicesFromMap(
                        searchCriteria.parameters.filters.religion,
                        'consumer',
                        'religion',
                      )
                    )
                  }}
                </td>
              </tr>
              <tr v-if="searchCriteria.parameters.filters.children.length">
                <th>Presence of Children</th>
                <td>
                  {{
                    toListSentence(
                      getFilterChoicesFromMap(
                        searchCriteria.parameters.filters.children,
                        'consumer',
                        'children',
                      )
                    )
                  }}
                </td>
              </tr>
            </template>

            <template v-else>
              <tr v-if="searchCriteria.parameters.filters.sic_code.length">
                <th>SIC Primary Code</th>
                <td>{{ toListSentence(searchCriteria.parameters.filters.sic_code) }}</td>
              </tr>
              <tr v-if="searchCriteria.parameters.filters.sales.length">
                <th>Sales Volume</th>
                <td>
                  {{
                    toListSentence(
                      getFilterChoicesFromMap(
                        searchCriteria.parameters.filters.sales,
                        'business',
                        'sales',
                      )
                    )
                  }}
                </td>
              </tr>
              <tr v-if="searchCriteria.parameters.filters.employees.length">
                <th>Number of Employees</th>
                <td>
                  {{
                    toListSentence(
                      getFilterChoicesFromMap(
                        searchCriteria.parameters.filters.employees,
                        'business',
                        'employees',
                      )
                    )
                  }}
                </td>
              </tr>
              <tr v-if="searchCriteria.parameters.filters.email_address.length">
                <th>Email Exists</th>
                <td>
                  {{
                    toListSentence(
                      getFilterChoicesFromMap(
                        searchCriteria.parameters.filters.email_address,
                        'business',
                        'email_address',
                      )
                    )
                  }}
                </td>
              </tr>
            </template>
          </table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getFilterChoicesMap } from '@/methods/get_filter_choices';

export default {
  data: () => ({
    viewportWidthAtLeast768: false,
    initialLoadAttempted: [],
    savedSearchesStatus: 'loading',
    savedSearches: {},
    savedSearchesDeletion: null,
    downloadsStatus: 'loading',
    downloads: {},
    downloadsDeletion: null,
    searchCriteria: null,
    searchCriteriaDialogShowing: false,
    filterChoicesMap: getFilterChoicesMap(),
  }),
  created() {
    const mql = window.matchMedia('(min-width: 768px)');

    if (mql.matches) {
      this.viewportWidthAtLeast768 = true;
    }

    mql.addEventListener('change', (e) => {
      this.viewportWidthAtLeast768 = e.matches;
    });

    this.loadSavedSearches();
    this.loadDownloads();
  },
  methods: {
    async loadSavedSearches(url) {
      this.savedSearchesStatus = 'loading';

      const defaultUrl = 'saved_searches/';

      if (this.savedSearches.url === undefined) {
        this.savedSearches.url = url || defaultUrl;
      }

      const responseData = await this.api({ url: this.savedSearches.url });

      if (responseData.status === 200) {
        this.savedSearches = responseData.body;
        this.savedSearchesStatus = 'loaded';
      } else if (responseData.status === 404) {
        this.savedSearches = {};
        this.loadSavedSearches();
      } else {
        this.savedSearchesStatus = 'error';
      }

      if (!this.initialLoadAttempted.includes('saved_searches')) {
        this.initialLoadAttempted.push('saved_searches');
      }
    },
    async deleteSavedSearch(id) {
      // eslint-disable-next-line no-alert
      if (window.confirm('Are you sure you want to delete this saved search?')) {
        this.savedSearchesDeletion = id;

        const responseData = await this.api({
          url: `saved_searches/${id}/`,
          method: 'DELETE',
        });

        this.savedSearchesDeletion = null;

        if ([204, 404].includes(responseData.status)) {
          this.savedSearches.results = this.savedSearches.results.filter(
            (savedSearch) => savedSearch.id !== id,
          );
        } else {
          // eslint-disable-next-line no-alert
          alert(
            'Unable to delete the saved search due to a communication error. '
            + 'Please check your connection and try again.',
          );
        }
      }
    },
    async loadDownloads(url) {
      this.downloadsStatus = 'loading';

      const defaultUrl = 'downloads/';

      if (this.downloads.url === undefined) {
        this.downloads.url = url || defaultUrl;
      }

      const responseData = await this.api({ url: this.downloads.url });

      if (responseData.status === 200) {
        this.downloads = responseData.body;
        this.downloadsStatus = 'loaded';
      } else if (responseData.status === 404) {
        this.downloads = {};
        this.loadDownloads();
      } else {
        this.downloadsStatus = 'error';
      }

      if (!this.initialLoadAttempted.includes('downloads')) {
        this.initialLoadAttempted.push('downloads');
      }
    },
    async deleteDownload(id) {
      // eslint-disable-next-line no-alert
      if (window.confirm('Are you sure you want to delete this download?')) {
        this.downloadsDeletion = id;

        const responseData = await this.api({
          url: `downloads/${id}/`,
          method: 'DELETE',
        });

        this.downloadsDeletion = null;

        if ([204, 404].includes(responseData.status)) {
          this.downloads.results = this.downloads.results.filter((download) => download.id !== id);
        } else {
          // eslint-disable-next-line no-alert
          alert(
            'Unable to delete the download due to a communication error. '
            + 'Please check your connection and try again.',
          );
        }
      }
    },
    getFilterChoicesFromMap(items, searchType, key) {
      return items.map((item) => this.filterChoicesMap[searchType][key][item]);
    },
  },
};
</script>

<style lang="scss" scoped>
  .v-alert a {
    color: #fff;
  }

  .wide-saved-search-table thead th:first-child,
  .wide-download-table thead th:first-child {
    width: 140px;
  }

  .narrow-saved-search-tables,
  .narrow-download-tables {
    display: grid;
    grid-gap: 40px;
    grid-template-columns: repeat(auto-fill, minmax(256px, 1fr));
  }

  .narrow-saved-search-table,
  .narrow-download-table {
    tr {
      height: 36px;
    }

    th,
    td {
      vertical-align: top;
    }

    th {
      padding-right: 8px;
      width: 6.7em;
      text-align: right;
    }

    td {
      word-break: break-word;
    }
  }

  .pagination {
    margin-top: 12px;
    text-align: center;

    button + button {
      margin-left: 8px;
    }
  }
</style>
